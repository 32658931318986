import {useLoaderData} from '@remix-run/react';
import type {LinksFunction} from '@remix-run/server-runtime';

import PageLayout from '@/components/shared/Page/PageLayout';
import {BlogSubNav} from '@/components/pages/blog/BlogSubNav/BlogSubNav';
import {TopicCard} from '@/components/blog/TopicCard/TopicCard';
import {useTranslations} from '@/components/shared/Page/I18N';
import Typography from '@/components/base/elements/Typography/Typography';
import type {Topic} from '@/components/blog/types';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import Conversion, {
  CtaStyles,
  HeadingStyles,
} from '@/components/pages/blog/SubscribeForm/Conversion/Conversion';
import blogStylesheet from '@/stylesheets/blog/blog.css?url';
import {BlogConversion} from '@/components/blog/BlogConversion/BlogConversion';
import {EN_BLOG_PATH} from '@/utils/blog/partnersHelpers';
import {partnersPath} from '@/hooks/navigation/utils';
import {useSiteData} from '@/components/shared/Page/SiteData';
import {PartnersFooter} from '@/pages/shopify.com/($locale)/partners/blog/components/PartnersFooter/PartnersFooter';
import {useBlogPageTitle} from '@/hooks/blog/useBlogPageTitle';

export {topicsLoader as loader} from '@/pages/shopify.com/($locale)/blog/topics/loader.server';
export {partnersHandle as handle} from '@/pages/shopify.com/($locale)/partners/blog/handle';

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: blogStylesheet}];
};

type TopicCard = {
  title: string;
  description: string;
  handle: string;
  image?: string;
  hidden?: boolean;
};

type TopicsLoaderData = {
  topicCards: {
    featuredTopics: TopicCard[];
    moreTopics: TopicCard[];
  };
};

export default function Topics() {
  const {t, localizePath, settings} = useTranslations();
  const {site} = useSiteData();
  const {topicCards} = useLoaderData<TopicsLoaderData>() ?? {};

  const featuredTopics = topicCards.featuredTopics;
  const moreTopics = topicCards.moreTopics;
  const linkText = t('linkText');

  const pageTitle = useBlogPageTitle(t('htmlHead.pageTitle'));

  return (
    <PageLayout
      title={pageTitle}
      header={(props) => (
        <BlogSubNav
          {...props}
          enBlogPath={EN_BLOG_PATH}
          blogPageType={BLOG_PAGE_TYPE.PARTNERS}
        />
      )}
      footer={<PartnersFooter />}
      isPureTitle
    >
      <div className="pt-36">
        <div className="py-10 tablet:py-12 desktop:py-16 bg-[#F6F6F1]">
          <div className="container">
            <Typography
              as="h1"
              className="text-3xl tablet:text-4xl desktop:text-5xl font-medium text-center"
            >
              {t('hero')}
            </Typography>
          </div>
        </div>
        <div className="container py-16">
          <Typography
            as="h2"
            className="text-t5 desktop:text-t4 font-aktivgroteskextended mb-8 desktop:mb-12 !font-medium"
          >
            {t('featuredTopics')}
          </Typography>
          <div className="grid grid-cols-1 tablet:grid-cols-3 gap-4 desktop:gap-6 tablet:gap-y-6 desktop:gap-y-9">
            {featuredTopics &&
              featuredTopics
                .filter((card) => !card.hidden)
                .map((topic: Topic, index: number) => (
                  <TopicCard
                    key={index}
                    image={topic.image}
                    title={topic.title}
                    description={topic.description}
                    linkText={linkText}
                    linkHref={localizePath(
                      `${EN_BLOG_PATH}topics/${topic.handle}`,
                    )}
                  />
                ))}
          </div>
        </div>
        <Conversion
          {...t('conversion')}
          headingClassName={HeadingStyles}
          className="border-y container py-16"
          cta={{...t('conversionSubscription.conversionCta')}}
          ctaClassName={CtaStyles({blogType: BLOG_PAGE_TYPE.PARTNERS})}
          subscriptionId={settings('subscription.settings.subscriptionId')}
        />
        <div className="container py-10 desktop:py-16">
          <Typography
            as="h2"
            className="text-t5 desktop:text-t4 font-aktivgroteskextended mb-8 desktop:mb-12 !font-medium"
          >
            {t('moreTopics')}
          </Typography>
          <div className="grid grid-cols-1 tablet:grid-cols-3 desktop:grid-cols-4 gap-4 desktop:gap-6 tablet:gap-y-6 desktop:gap-y-9">
            {moreTopics &&
              moreTopics
                .filter((card) => !card.hidden)
                .map((topic: Topic, index: number) => (
                  <TopicCard
                    key={index}
                    image={topic.image}
                    title={topic.title}
                    description={topic.description}
                    linkText={linkText}
                    linkHref={localizePath(
                      `${EN_BLOG_PATH}topics/${topic.handle}`,
                    )}
                  />
                ))}
          </div>
        </div>
        <BlogConversion
          heading={t('sellAnywhere.headingHtml')}
          buttonHref={partnersPath(site)}
          buttonText={t('sellAnywhere.buttonText')}
          mode="partners"
        />
      </div>
    </PageLayout>
  );
}
